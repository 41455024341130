import {Redirect, useLocation} from 'react-router-dom'
import '../../styles/reset.css'

import './App.css'
import { pure } from 'recompose';

interface Props {
}

/**
 * This component is needed to properly trigger Cloudflare caching mechanisms for end user app
 * @constructor
 */
const Embed: React.FC<Props> = ({ }) => {

  const location = useLocation()

  let search = new URLSearchParams(location.search)

  let paramExperienceId = 'contester_experienceId'
  let paramCardId = 'contester_cardId'

  let experienceId = search.get(paramExperienceId)!!
  let cardId = search.get(paramCardId)

  let path = `/${experienceId}`
  if (cardId) {
    path += `/${cardId}`
  }

  search.delete(paramExperienceId)
  search.delete(paramCardId)
  if (search.toString().length > 0) {
    path += "?" + search.toString()
  }

  return (
    <Redirect to={path}/>
  )
}

export default pure(Embed)
