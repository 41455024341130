import {LiveChatHistory} from "./types";
import {IS_STAGING} from "../utils/Constants";

class LiveChatAPI {

  private apiBasePath = IS_STAGING
    ? 'https://staging-api.contester.net'
    : 'https://api.contester.net'

  getHistory = async (
    organizationId: string,
    experienceId: string,
    referenceId: string,
    hash: string,
    fromMessageId: string | null,
  ): Promise<LiveChatHistory> => {
    let url = `${this.apiBasePath}/api/public/livechats/${organizationId}/${referenceId}/history?h=${hash}`
    if (fromMessageId != null) {
      url += `&fromMessageId=${fromMessageId}`
    }

    const d = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'contester-product-id': experienceId,
      },
    })
    return await d.json()
  }
}

export const liveChatAPI = new LiveChatAPI()
