import React, { createContext, useContext, useReducer } from 'react'
import reducer, {initialState, ReducerState} from "../../store/reducer";

export const StateContext = createContext<[ state: ReducerState, dispatch: React.Dispatch<any>]>([ initialState, () => null])

// @ts-ignore
export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StateContext.Provider value={[state, dispatch]}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateValue = () => useContext(StateContext)
