import { isEqual } from 'lodash'

import {
  SET_IS_LOADING,
  SET_SP_ID,
  SET_ITEMS,
  SET_BRAND,
  SET_TOTAL_INTERACTIONS,
  SET_CARD_UUID,
  SET_HEADER,
  SET_PARENT_CARD,
  SET_VIDEO_CURRENT_TIME,
  SET_ISSET_VIDEO_CURRENT_TIME,
  IS_WIDGET_MAXIMIZED,
  IS_WIDGET_EMBEDDED,
  TITLE_STYLE,
  SET_SP_HASH,
  SET_SEQUENCE_STATISTICS,
  SET_SEQUENCE_LIVE_DATA,
  SET_WIDGET_LOCATION,
  SET_WIDGET_LOCATION_REFERER,
  SET_LIVESTREAM,
  SET_ORGANIZATION_ID,
  SET_LIVE_CHATS_DATA,
  SET_LIVE_CHAT_HISTORY,
  SET_LIVE_CHAT_HISTORY_HASH,
  ADD_MESSAGE_TO_LIVE_CHAT,
  ADD_MESSAGE_REPLY_TO_LIVE_CHAT,
  ADD_MESSAGE_REACTION_TO_LIVE_CHAT,
  SET_SESSION_ESTABLISHED,
  EXPERIENCE_EVENT_LIKED,
  EXPERIENCE_EVENT_SHARED,
  REPLACE_PENDING_MESSAGE_TO_LIVE_CHAT,
  SET_SHARE_URL,
  SET_LIVESTREAM_VIEWERS,
  SET_USER_EMAIL,
  SET_LIVE_CHAT_MODE, SET_TRANSLATIONS,
  SET_SURF,
  SET_LIVE_CHAT,
  SET_GRID_AREA_CONTENT,
  SET_BRAND_FOLLOWING,
  AUTOPILOT_CARD_FOCUS,
  NAVIGATE_CARD,
  SET_EXPERIENCE_ROOT_CARD,
  SET_LIVESTREAM_SUBSCRIBED,
} from '../constants/actions'

import {
  EventInteractionType,
  LiveChatDataObject,
  LiveChatMessageReactionSent,
  LiveChatMessageReplySent,
  LiveChatMessageSent, ServerSettingsLiveData, ServerSettingsSessionLiveData,
  ws,
} from '../events/ws'

import {
  liveChatHistoryMessageFormatter,
  liveChatHistoryMessageReplyFormatter,
} from './helpers'
import {
  BrandDTO,
  CardData,
  Header,
  LiveChatHistory,
  LiveChatHistoryItem,
  LiveChatHistoryItemReaction,
  LiveStreamData
} from "../api/types";
import {sendToWidget} from "../utils/postMessages";
import {IS_STAGING} from "../utils/Constants";
import {appendQueryParameter, navigateCardHierarchy} from "../modules/app/App";
import {log} from "../utils/log";
import {localStorageAppendToTimestamps} from "../utils/localStorage";

export interface ReducerState {
  rootCard: CardData | null,
  sequencePollUuid: string | null,
  sequencePollHash: string | null,
  cardUuid: string | null,
  items: [],
  api_url: string
  muxEnvKey: string
  brand: BrandDTO | null,
  totalInteractions: null,
  isLoading: boolean,
  sliderTo: null,
  liveData: ServerSettingsLiveData | null,
  sessionData: ServerSettingsSessionLiveData | null,
  liveStream: LiveStreamData | null,
  statistics: {},
  parentCardId: null,
  header: Header | null,
  titleStyle: null,
  prevVideoCurrentTime: 0,
  isSetVideoCurrentTime: boolean,
  isWidgetMaximized: boolean,
  isWidgetEmbedded: boolean,
  widgetLocation: null,
  widgetLocationReferer: null,
  gridAreaContent: 'Grid' | 'LiveStreamReminderForm' | 'LiveChat' | 'BrandFollowForm'
  organizationId: null,
  liveChatMode: 'NRT' | 'RT', // by default it's near-real-time
  liveChats: LiveChatDataObject[],
  liveChat: null,
  liveChatHistories: LiveChatHistory[],
  shareUrl: undefined,
  userEmail: null,
  translations: {
    [key: string]: string,
  },
  surf: boolean,
  isSessionEstablished: boolean,
  surfMode: 'Manual',
  brandFollowing: boolean,
  liveStreamSubscribed: boolean,
}

export const initialState: ReducerState = {
  rootCard: null,
  sequencePollUuid: '', //default
  sequencePollHash: 'default',
  cardUuid: null,
  items: [],
  api_url:
    (IS_STAGING
      ? 'https://staging-api.contester.net'
      : 'https://api.contester.net') + '/api/public',
  muxEnvKey:
      IS_STAGING
          ? '9uijq7pr8js1l0ppcjgcin7bs'
          : 'k1aguct97jh7p5k8fcueev46i',
  brand: null,
  totalInteractions: null,
  isLoading: false,
  sliderTo: null,
  liveData: null,
  sessionData: null,
  liveStream: null,
  statistics: {},
  parentCardId: null,
  header: null,
  titleStyle: null,
  prevVideoCurrentTime: 0,
  isSetVideoCurrentTime: false,
  isWidgetMaximized: false,
  isWidgetEmbedded: false,
  widgetLocation: null,
  widgetLocationReferer: null,
  gridAreaContent: 'Grid',
  organizationId: null,
  liveChatMode: 'NRT', // by default it's near-real-time
  liveChats: [],
  liveChat: null,
  liveChatHistories: [],
  shareUrl: undefined,
  userEmail: null,
  translations: {},
  surf: false,
  isSessionEstablished: false,
  surfMode: 'Manual',
  brandFollowing: false,
  liveStreamSubscribed: false,
}

const reducer = (state: ReducerState, action: any): ReducerState => {
  switch (action.type) {
    case SET_EXPERIENCE_ROOT_CARD:
      if (isEqual(state.rootCard, action.root)) {
        return state
      }
      return {
        ...state,
        rootCard: action.root
      }
    case SET_LIVESTREAM_SUBSCRIBED:
      if (isEqual(state.liveStreamSubscribed, action.liveStreamSubscribed)) {
        return state
      }
      return {
        ...state,
        liveStreamSubscribed: action.liveStreamSubscribed,
      }
    case SET_BRAND_FOLLOWING:
      if (isEqual(state.brandFollowing, action.brandFollowing)) {
        return state
      }
      return {
        ...state,
        brandFollowing: action.brandFollowing,
      }
    case NAVIGATE_CARD:

      let card = navigateCardHierarchy(state.rootCard!!, action.cardId, undefined)

      let initiatedBy: "User" | "System" = action.initiatedBy

      if (card) {
        let sessionId = state.sessionData?.id
        localStorageAppendToTimestamps(state.cardUuid)
        if (card.cardType === "DIRECT_LINK") {
          log("NAVIGATION direct link click")

          if (state.isWidgetEmbedded) {
            ws.sendEvent(EventInteractionType.CARD_DIRECT_LINK_CLICKED, card.cardId)
            // pass navigation event to widget
            let directLinkUrl = card.cardTypeParameters.directLinkUrl
            if (sessionId) {
              // this will make sure that if Hadi switches user agents, it will not give error on direct url click
              directLinkUrl = appendQueryParameter(directLinkUrl, `contester-session-id=${sessionId}`)
            }
            sendToWidget(
              {
                type: 'contester-navigation',
                value: {
                  url: directLinkUrl,
                  host: card.cardTypeParameters.directLinkHost,
                  directLinkQueryString: state.sessionData?.queryString,
                  directLinkRelativePath: card.cardTypeParameters.directLinkRelativePath,
                  directLinkContainsExperience: card.cardTypeParameters.directLinkContainsExperience,
                  directLinkContainsAnotherExperience: card.cardTypeParameters.directLinkContainsAnotherExperience,
                  directLinkValid: card.cardTypeParameters.directLinkValid,
                  directLinkCanOverlaySelf: card.cardTypeParameters.directLinkCanOverlaySelf,
                  directLinkCanOverlayAnywhere: card.cardTypeParameters.directLinkCanOverlayAnywhere,
                  directLinkAlwaysOpenInNewTab: card.cardTypeParameters.directLinkAlwaysOpenInNewTab,
                }
              },
            )
          } else {
            ws.sendEvent(EventInteractionType.CARD_DIRECT_LINK_CLICKED, card.cardId)
            window.open(card.cardTypeParameters.directLinkUrl, '_PARENT')
          }

        } else {
          log("NAVIGATION intermediate card click")

          let sequencePollUuid = state.sequencePollUuid
          let cardUuid = action.cardId ?? ''

          if (initiatedBy === "System") {
            sendToWidget(
              {
                type: 'contester-step',
                value: {
                  prevUrl: '/' + sequencePollUuid + '/' + cardUuid,
                }
              },
            )
          }

          action.history.push(
            '/' +
            sequencePollUuid +
            '/' +
            cardUuid +
            action.history.location.search,
          )

          sendToWidget(
            {
              type: "do-maximize",
              value: {},
            },
          )
        }
      }

      return state
    case SET_SURF:
      if (isEqual(state.surf, action.surf) && isEqual(state.surfMode, action.mode)) {
        return state
      }
      return {
        ...state,
        surf: action.surf,
        surfMode: action.mode,
      }
    case SET_GRID_AREA_CONTENT:
      if (isEqual(state.gridAreaContent, action.gridAreaContent)) {
        return state
      }
      return {
        ...state,
        gridAreaContent: action.gridAreaContent
      }
    case SET_TRANSLATIONS:
      if (isEqual(state.translations, action.translations)) {
        return state
      }
      return {
        ...state,
        translations: action.translations,
      }
    case SET_LIVE_CHAT_MODE:
      if (isEqual(state.liveChatMode, action.liveChatMode)) {
        return state
      }
      return {
        ...state,
        liveChatMode: action.liveChatMode,
      }
    case SET_HEADER:
      if (isEqual(state.header, action.header)) {
        return state
      }
      return {
        ...state,
        header: action.header,
      }
    case SET_PARENT_CARD:
      if (isEqual(state.parentCardId, action.parentCardId)) {
        return state
      }
      return {
        ...state,
        parentCardId: action.parentCardId,
      }
    case SET_SP_ID: {
      if (isEqual(state.sequencePollUuid, action.uuid)) {
        return state
      }
      return {
        ...state,
        sequencePollUuid: action.uuid,
      }
    }
    case SET_SHARE_URL: {
      if (isEqual(state.shareUrl, action.shareUrl)) {
        return state
      }
      return {
        ...state,
        shareUrl: action.shareUrl,
      }
    }
    case SET_SP_HASH: {
      if (isEqual(state.sequencePollHash, action.hash)) {
        return state
      }
      if (
        action.hash !== undefined &&
        action.hash !== '' &&
        state.isWidgetEmbedded &&
        action.postToWidget
      ) {
        if (window.parent)
          sendToWidget(
            { type: 'experience-hash', value: { hash: action.hash } },
          )
      }
      return {
        ...state,
        sequencePollHash: action.hash,
      }
    }
    case SET_USER_EMAIL:
      if (isEqual(state.userEmail, action.userEmail)) {
        return state
      }
      return {
        ...state,
        userEmail: action.userEmail,
      }
    case SET_IS_LOADING:
      if (isEqual(state.isLoading, action.isLoading)) {
        return state
      }
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case SET_SEQUENCE_LIVE_DATA:
      if (isEqual(state.liveData, action.liveData) && isEqual(state.sessionData, action.sessionData)) {
        return state
      }
      return {
        ...state,
        liveData: action.liveData,
        sessionData: action.sessionData,
      }
    case SET_SEQUENCE_STATISTICS:
      if (isEqual(state.statistics, action.statistics)) {
        return state
      }
      return {
        ...state,
        statistics: action.statistics,
      }
    case SET_LIVE_CHAT:
      if (isEqual(state.liveChat, action.liveChat)) {
        return state
      }
      return {
        ...state,
        liveChat: action.liveChat,
      }
    case SET_LIVESTREAM:
      if (isEqual(state.liveStream, action.liveStream)) {
        return state
      }
      return {
        ...state,
        liveStream: action.liveStream,
      }
    case SET_BRAND:
      if (isEqual(state.brand, action.brand)) {
        return state
      }
      return {
        ...state,
        brand: action.brand,
      }
    case SET_CARD_UUID:
      if (isEqual(state.cardUuid, action.cardUuid)) {
        return state
      }
      ws.setCard(action.cardUuid)
      return {
        ...state,
        cardUuid: action.cardUuid,
      }
    case SET_TOTAL_INTERACTIONS:
      if (isEqual(state.totalInteractions, action.totalInteractions)) {
        return state
      }
      return {
        ...state,
        totalInteractions: action.totalInteractions,
      }
    case SET_ITEMS:
      if (isEqual(state.items, action.items)) {
        return state
      }
      return {
        ...state,
        items: action.items,
      }
    case SET_VIDEO_CURRENT_TIME:
      if (isEqual(state.prevVideoCurrentTime, action.prevVideoCurrentTime)) {
        return state
      }
      return {
        ...state,
        prevVideoCurrentTime: action.prevVideoCurrentTime,
      }
    case SET_ISSET_VIDEO_CURRENT_TIME:
      if (isEqual(state.isSetVideoCurrentTime, action.isSetVideoCurrentTime)) {
        return state
      }
      return {
        ...state,
        isSetVideoCurrentTime: action.isSetVideoCurrentTime,
      }
    case IS_WIDGET_MAXIMIZED: {
      if (isEqual(state.isWidgetMaximized, action.isWidgetMaximized)) {
        return state
      }
      return {
        ...state,
        isWidgetMaximized: action.isWidgetMaximized,
      }
    }
    case SET_WIDGET_LOCATION: {
      if (isEqual(state.widgetLocation, action.location)) {
        return state
      }
      return {
        ...state,
        widgetLocation: action.location,
      }
    }
    case SET_WIDGET_LOCATION_REFERER: {
      if (isEqual(state.widgetLocationReferer, action.location)) {
        return state
      }
      return {
        ...state,
        widgetLocationReferer: action.location,
      }
    }
    case IS_WIDGET_EMBEDDED:
      if (isEqual(state.isWidgetEmbedded, true)) {
        return state
      }
      return {
        ...state,
        isWidgetEmbedded: true,
      }
    case TITLE_STYLE:
      if (isEqual(state.titleStyle, action.titleStyle)) {
        return state
      }
      return {
        ...state,
        titleStyle: action.titleStyle,
      }
    case SET_ORGANIZATION_ID:
      if (isEqual(state.organizationId, action.organizationId)) {
        return state
      }
      return {
        ...state,
        organizationId: action.organizationId,
      }
    case SET_LIVE_CHAT_HISTORY:

      let history = action.history

      let newHistories = state.liveChatHistories.some(
          (h: LiveChatHistory) => h.liveChatId === history.liveChatId,
        )
          ? state.liveChatHistories.map((lh: LiveChatHistory) => {
            if (lh.liveChatId === history.liveChatId) {
              return history
            } else {
              return lh
            }
          })
          : [history]

      if (isEqual(newHistories, state.liveChatHistories)) {
        return state
      }
      return {
        ...state,
        liveChatHistories: newHistories,
      }
    case ADD_MESSAGE_TO_LIVE_CHAT:
      return {
        ...state,
        liveChatHistories: state.liveChatHistories.map((h: LiveChatHistory) => {
          let message: LiveChatMessageSent = action
          if (h.liveChatId === message.liveChatId) {
            return {
              ...h,
              history: h.history.concat(liveChatHistoryMessageFormatter(message))
            }
          } else {
            return h
          }
        }),
      }
    case REPLACE_PENDING_MESSAGE_TO_LIVE_CHAT:
      return {
        ...state,
        liveChatHistories: state.liveChatHistories.map((h: LiveChatHistory) => {
          let message: LiveChatMessageSent = action
          if (h.liveChatId === message.liveChatId) {
            for (var i = h.history.length - 1; i >= 0; i--) {
              let msg = h.history[i]
              if (msg && msg.id === 'PENDING' && msg.message === message.message) {
                msg.id = message.id
                break
              }
            }
            return {
              ...h,
              history: h.history
            }
          } else {
            return h
          }
        }),
      }
    case ADD_MESSAGE_REPLY_TO_LIVE_CHAT:
      return {
        ...state,
        liveChatHistories: state.liveChatHistories.map((h: LiveChatHistory) => {
          let reply: LiveChatMessageReplySent = action
          if (reply.liveChatId === h.liveChatId) {
            let repliedIndex = h.history.findIndex((item: LiveChatHistoryItem) => {
              return item.id === reply.replyMessageId
            })
            if (repliedIndex > -1) {
              let repliedMessage = h.history[repliedIndex]
              if (!repliedMessage.replies.find((item: LiveChatHistoryItem) => { return item.id === reply.id })) {
                repliedMessage.replies = [
                  ...repliedMessage.replies,
                  liveChatHistoryMessageReplyFormatter(reply)
                ]
              }
              return {
                ...h,
                history: h.history.slice(0, repliedIndex).concat(h.history.slice(repliedIndex + 1, h.history.length)).concat(repliedMessage)
              }
            } else {
              return h
            }
          } else {
            return h
          }
        }),
      }
    case ADD_MESSAGE_REACTION_TO_LIVE_CHAT:
      return {
        ...state,
        liveChatHistories: state.liveChatHistories.map((h: LiveChatHistory) => {
          let reaction: LiveChatMessageReactionSent = action
          if (reaction.liveChatId === h.liveChatId) {
            return {
              ...h,
              history: h.history.map((m: LiveChatHistoryItem) => {
                if (m.id === reaction.messageId) {
                  return {
                    ...m,
                    reactions: m.reactions.some((r: LiveChatHistoryItemReaction) =>
                        r.code === reaction.reactionCode,
                    ) ? m.reactions.map((r: LiveChatHistoryItemReaction) => {
                        if (r.code === reaction.reactionCode) {
                          return {
                            ...r,
                            count: r.count + 1
                          }
                        } else {
                          return r
                        }
                      },
                    ) : [
                      {
                        code: reaction.reactionCode,
                        count: 1,
                      },
                    ]
                  }
                } else {
                  return m
                }
              })
            }
          } else {
            return h
          }
        }),
      }
    case SET_LIVE_CHAT_HISTORY_HASH:
      return {
        ...state,
        liveChats:
          state.liveChats.map((lc: LiveChatDataObject) => {
            if (lc.liveChatId === action.liveChatId) {
              return {
                ...lc,
                hash: action.hash
              }
            } else {
              return lc
            }
          })
      }
    case AUTOPILOT_CARD_FOCUS:
      return state

    case SET_LIVESTREAM_VIEWERS:
      if (isEqual(state.liveData?.liveStreamViewers, action.viewers)) {
        return state
      }
      return {
        ...state,
        liveData: {
          ...state.liveData!!,
          liveStreamViewers: action.viewers,
        }
      }
    case SET_LIVE_CHATS_DATA:
      if (isEqual(state.liveChats, action.liveChats)) {
        return state
      }
      return {
        ...state,
        liveChats: action.liveChats,
      }
    case SET_SESSION_ESTABLISHED:
      if (isEqual(state.isSessionEstablished, action.payload)) {
        return state
      }
      return {
        ...state,
        isSessionEstablished: action.payload,
      }
    case EXPERIENCE_EVENT_LIKED: {

      let card = state.liveData!!.cards[action.cardId]
      if (card) {
        card = {
          ...card,
          likes: action.likes,
          liked: card.liked || action.sessionId,
        }
        state.liveData!!.cards[action.cardId] = card
      }
      return {
        ...state,
      }
    }
    case EXPERIENCE_EVENT_SHARED: {

      let card = state.liveData!!.cards[action.cardId]
      if (card) {
        card = {
          ...card,
          shares: action.shares,
          shared: card.shared || action.sessionId,
        }
        state.liveData!!.cards[action.cardId] = card
      }
      return {
        ...state,
      }
    }
    default:
      return state
  }
}

export default reducer
