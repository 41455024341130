import React from 'react'
import classnames from 'classnames'

import { isMobile } from 'react-device-detect'
import { ReactComponent as Logo } from '../../img/logoDesktop.svg'
import { ReactComponent as LogoMobile } from '../../img/logoMobile.svg'
import { useStateValue } from '../state-provider/StateProvider'
import {pure} from "recompose";

const FooterLogo = () => {
  const [{ isWidgetEmbedded }] = useStateValue()
  const redirectToContester = e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    window.open('https://contester.net', '_blank')
  }
  return (
    <div className="contester-logo-wrapper">
      {isMobile ? (
        <LogoMobile
          onClick={redirectToContester}
          className="footer-logo-mobile"
        />
      ) : (
        <Logo
          onClick={redirectToContester}
          className={classnames('footer-logo', { white: isWidgetEmbedded })}
        />
      )}
    </div>
  )
}
export default pure(FooterLogo)
