import { default as classnames, default as classNames } from 'classnames'
import React, {useCallback, useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import {
    setIsLoading,
    navigateCard,
} from '../app/App.action'
import LoadingIndicator from '../loading-indicator/LoadingIndicator'
import { useStateValue } from '../state-provider/StateProvider'
import './PhotoGrid.css'
import {pure} from "recompose";

export default pure(function PhotoGrid() {
  const [
    {
      items,
      isLoading,
      cardUuid,
      header,
      isReminderFormVisible,
      isLiveChatVisible,
    },
    dispatch,
  ] = useStateValue()

  let history = useHistory()
  let [showFadeIn, setShowFadeIn] = useState(true)

  useEffect(() => {
    if (items.length > 0 && showFadeIn) {
      setShowFadeIn(true)
    } else {
      setShowFadeIn(false)
    }
  }, [cardUuid, items, showFadeIn])

  const setCard = useCallback((cardUuid) => {
    dispatch(setIsLoading({ isLoading: true }))
    dispatch(navigateCard(cardUuid, history, "User"))
    dispatch(setIsLoading({ isLoading: false }))
  }, [dispatch, history])

  // hardcoded value for possibility to control highlighting from contester panel
  const allowHighlight = false
  return (
      <div
          style={{ ...(items?.length > 6 && { gridTemplateRows: `repeat(${Math.ceil(items?.length / 2)}, 1fr)` }) }}
          className={classnames(
              {
                  'Split-One': items?.length === 1,
                  'Split-Two': items?.length === 2,
                  'Split-Three': items?.length === 3,
                  'Split-Four': items?.length === 4,
                  'Split-Five': items?.length === 5,
                  'Split-Six': items?.length === 6,
                  // Need to clean styles and do not use mapping to Split-Five and Split-Six after this
                  'Split-Five Split-Odd': items?.length > 6 && items?.length % 2 === 1,
                  'Split-Six Split-Even': items?.length > 6 && items?.length % 2 === 0,
                  gridAmplify: items?.length === 0,
                  'no-header': header == null,
                  'is-loading': isLoading,
                  'no-scroll': isReminderFormVisible || isLiveChatVisible
              },
              'Photo-Grid',
              'isSafari',
          )}
      >

          {items?.map((item, index) => (
              <React.Fragment key={index}>
                  <div
                      className={classNames(`card-item card-${index + 1}`, {
                          'is-highlighted': allowHighlight,
                      })}
                  >
                      <img
                          height={items?.length === 0 ? '100%' : null}
                          style={{
                              animation: !isLoading ? 'fadeIn ease-in-out 200ms' : null,
                              zIndex: -10,
                              objectPosition:
                                  item.cardStyleParameters.objectPosition ?? '50% 50%',
                          }}
                          id={item.cardId}
                          className={classnames(
                              { amplifyPic: items.length === 0 },
                              'grid-photo',
                              'isSafari',
                          )}
                          draggable="false"
                          alt="Product Variant"
                          onError={e => {
                              e.target.onerror = null
                              e.target.src = 'default.png'
                          }}
                          onClick={() => setCard(item.cardId)}
                          pos={index + 1}
                          src={item.image.src}
                      />
                      <LoadingIndicator
                          item={item}
                          setCard={setCard}
                          pos={index + 1}
                          show={item.cardId === cardUuid}
                      />
                  </div>
              </React.Fragment>
          ))}
      </div>
  )
})
