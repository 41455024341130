import { isMobile, useMobileOrientation } from 'react-device-detect'
import Ripple from '../ripple/Ripple'
import { useStateValue } from '../state-provider/StateProvider'
import './LoadingIndicator.css'
import { ImageWrapper } from './SequenceImageSingleStyles'
import CardProduct from "./CardProduct";
import {pure} from "recompose";

function LoadingIndicator({
  pos,
  show,
  setCard,
  item,
  isLoading,
}) {
  const [
    { items },
  ] = useStateValue()

  const cardStyle = JSON.parse(item.cardStyleParameters.labelStyle || '{}')
  const { isLandscape } = useMobileOrientation()
  const setColorWithOpacity = (colour, value) => {
    const opacity = Math.floor(value * 255).toString(16)
    return colour + opacity
  }

  var colorWithoutHex = cardStyle?.backgroundColor
    ? cardStyle?.backgroundColor.substring(1)
    : '000000'
  const getContrastYIQ = hexcolor => {
    hexcolor = hexcolor.replace('#', '')
    var r = parseInt(hexcolor.substr(0, 2), 16)
    var g = parseInt(hexcolor.substr(2, 2), 16)
    var b = parseInt(hexcolor.substr(4, 2), 16)
    var yiq = (r * 299 + g * 587 + b * 114) / 1000
    return yiq >= 128 ? '#020000' : '#ffffff'
  }

  return (
    <div
      className={`imageWrapper LoadingIndicator show isSafari pos${pos}`}
      key={item?.cardId}
      onClick={() => setCard(item.cardId)}
    >
      <ImageWrapper
        opacity={cardStyle?.['opacity'] / 100 || '0'}
        background={cardStyle?.backgroundColor || '#000000'}
      >
        <span
          style={{
            ...cardStyle,
            fontSize:
              isLandscape && isMobile && cardStyle?.fontSize
                ? `${parseInt(cardStyle.fontSize * 0.7).toFixed()}px`
                : cardStyle.fontSize,
            position: 'absolute',
            top: '50%',
            width: '90%',
            left:
              cardStyle?.aligment === 'left'
                ? '0%'
                : cardStyle?.aligment === 'center' ||
                  cardStyle?.aligment === undefined
                  ? '50%'
                  : 'unset',
            right: cardStyle?.aligment === 'right' ? '0%' : 'unset',
            transform:
              cardStyle?.aligment === 'center' ||
                cardStyle?.aligment === undefined
                ? 'translate(-50%, -50%)'
                : 'translate(0%, -50%)',
            opacity: 1,
            backgroundColor: 'transparent',
            zIndex: 998,
          }}
        >
          {item?.cardLabel}
        </span>
        {item?.product && (
          <CardProduct product={item.product} />
        )}
        {show && isLoading && items.length > 0 && (
          <div className="circle-loader"></div>
        )}
        <Ripple
          color={setColorWithOpacity(
            `${getContrastYIQ(colorWithoutHex)}`,
            cardStyle?.opacity && cardStyle?.opacity < 50
              ? cardStyle?.['opacity'] / 100
              : 0.4,
          )}
          duration={700}
        />
      </ImageWrapper>
    </div>
  )
}

export default pure(LoadingIndicator)
