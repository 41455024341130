import React, {FC, useCallback} from 'react'
import { isEmpty } from 'lodash'

import './TopBar.css'
import { useStateValue } from '../state-provider/StateProvider'
import classnames from 'classnames'
import {pure} from "recompose";
import {setGridAreaContent} from "../app/App.action";

export const TopBar: FC<{ hasTitle: boolean }> = ({
  hasTitle,
}) => {

  const [{ brand, header, items, brandFollowing, translations, gridAreaContent }, dispatch] = useStateValue()

  const brandFollowButtonClick = useCallback(() => {

    if (gridAreaContent !== 'BrandFollowForm') {
      dispatch(setGridAreaContent('BrandFollowForm'))
    } else {
      dispatch(setGridAreaContent('Grid'))
    }
  }, [dispatch, gridAreaContent])

  return (
    <>
      {!isEmpty(items) && (
        <div
          className={classnames('TopBar', {
            'no-header': header === null,
          })}
        >
          {!hasTitle && (
            <div className="company-info">
              {brand?.imageLink && (
                <div className="logo-wrap">
                  <img
                    draggable="false"
                    alt=""
                    src={brand?.imageLink}
                    className="logo"
                  />
                </div>
              )}
              <div className="company-name">{brand?.title}</div>
              <button className="brandFollowButton" onClick={brandFollowButtonClick}>
                {brandFollowing ? translations['FollowHeaderButtonLabelFollowing'] : translations['FollowHeaderButtonLabelFollow']}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default pure(TopBar)
