import React, {useEffect, useState} from 'react'
import classnames from 'classnames'
import {setBrandFollowing, setGridAreaContent, setUserEmail} from '../../app/App.action'
import { useStateValue } from '../../state-provider/StateProvider'
import {ws} from "../../../events/ws";
import {sendToWidget} from "../../../utils/postMessages";
import '../form-components.css'
import './BrandFollowForm.css'
import {asset} from "../../../utils/utils";
import Countdown from "../../countdown/Countdown";
import {log} from "../../../utils/log";

const BrandFollowForm = ({
}) => {
  const [{ brand, isWidgetMaximized, userEmail, translations, brandFollowing }, dispatch] = useStateValue()
  const showFollowers = brand && ((brand?.followers ?? 0) > 999)
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (userEmail) {
            setEmail(userEmail)
        }
    }, [userEmail]);

  const sendFollowForm = async (e: any) => {

    e.preventDefault()
    setIsLoading(true)
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    try {
        ws.sendFollowRequest(
            email,
            timezone,
        )
        dispatch(setUserEmail({ userEmail: email }))
        sendToWidget({ type: 'update-user-email', value: email })
        dispatch(setBrandFollowing(true))

        setTimeout(() => {
          dispatch(setGridAreaContent('Grid'))
        }, 3000)
        setIsLoading(false)
    } catch (err) {
      log(`Error following`, err)
      setIsLoading(false)
    }
  }
  return (
    <>
      <div className="reminder-bg">
        <img src={brand?.imageLinkBlurred} />
      </div>
      <div className="reminder-content">

       <div className="brand-follow-form-brand-header">
         <div className="brand-follow-form-avatar">
           <img src={brand?.imageLink}/>
         </div>
         <div className={classnames("brand-follow-form-name", {centered: !showFollowers})}>
           {brand?.title}
         </div>
         { showFollowers && (
           <div className={"brand-follow-form-followers"}>
             {translations['FollowFormFollowersLabel'].replace('{0}', ('' + brand.followersFormatted))}
           </div>
         )}
         <div className={"brand-follow-form-note"}>
           {brand?.profileDescriptionForFollowers ?? 'note'}
         </div>
       </div>

        <form onSubmit={sendFollowForm} className={classnames("reminder-container", {show: !brandFollowing})}>
          <input
            tabIndex={-1}
            className="show"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder={translations['FollowFormInputEmailLabel']}
            id="email"
            name="email"
            disabled={isLoading}
            required
          ></input>
          <button
            disabled={isLoading}
            className="remind-me"
            type="submit"
            // style={extendedStyles}
          >
            {translations['FollowFormSubmitLabel']}
          </button>

          <div className={"brand-follow-form-explanation"}>
            {translations['FollowFormExplanationLabel']}
          </div>
        </form>
        {brandFollowing && (
          <>
            <div className={classnames("thank-you-label", "show", "m20")}>
              <span>{translations['FollowFormFollowingLabel']}</span>
              {<img src={asset('icon-star.svg')}/>}
            </div>
          </>
        )}
      </div>
    </>
  )

  // return (
  //   <>
  //     <div className="brand-follow-form-bg">
  //       <img src={brand?.imageLink} />
  //     </div>
  //     <div className="brand-follow-form-fg">
  //       <div className="brand-follow-form-content">
  //

  //         <form
  //           onSubmit={sendFollowForm}
  //           className={classnames("form-container", {show: !brandFollowing})}>
  //           <input
  //             tabIndex={-1}
  //             className="show"
  //             type="email"
  //             value={email}
  //             onChange={e => setEmail(e.target.value)}
  //             placeholder={translations['SubscriptionFormInputEmailLabel']}
  //             id="email"
  //             name="email"
  //             disabled={isLoading}
  //             required
  //           ></input>
  //           <button
  //             disabled={isLoading}
  //             className="remind-me"
  //             type="submit"
  //             // style={extendedStyles}
  //           >
  //             {translations['SubscriptionFormButtonSubscribeLabel']}
  //           </button>

  //         </form>

  //       </div>
  //     </div>
  //
  //   </>
  // )
}

export default BrandFollowForm
