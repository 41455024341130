import {
  SET_IS_LOADING,
  SET_SP_ID,
  SET_ITEMS,
  SET_BRAND,
  SET_TOTAL_INTERACTIONS,
  SET_CARD_UUID,
  SET_PARENT_CARD,
  SET_VIDEO_CURRENT_TIME,
  SET_ISSET_VIDEO_CURRENT_TIME,
  IS_WIDGET_MAXIMIZED,
  IS_WIDGET_EMBEDDED,
  TITLE_STYLE,
  SET_SP_HASH,
  SET_SEQUENCE_STATISTICS,
  SET_SEQUENCE_LIVE_DATA,
  SET_WIDGET_LOCATION,
  SET_WIDGET_LOCATION_REFERER,
  SET_LIVESTREAM,
  EXPERIENCE_EVENT_SHARED,
  EXPERIENCE_EVENT_LIKED,
  SET_LIVESTREAM_VIEWERS,
  SET_USER_EMAIL,
  SET_LIVE_CHAT_MODE,
  SET_TRANSLATIONS,
  SET_SURF,
  SET_LIVE_CHAT,
  SET_GRID_AREA_CONTENT,
  SET_BRAND_FOLLOWING,
  AUTOPILOT_CARD_FOCUS,
  NAVIGATE_CARD,
  SET_EXPERIENCE_ROOT_CARD,
  SET_LIVESTREAM_SUBSCRIBED,
} from '../../constants/actions'

import {initialState} from "../../store/reducer";
import {CardData} from "../../api/types";
import {ServerSettingsLiveData} from "../../events/ws";

// @ts-ignore
const setIsLoading = payload => ({
  type: SET_IS_LOADING,
  ...payload,
})

// @ts-ignore
const setSpId = payload => ({
  type: SET_SP_ID,
  ...payload,
})

// @ts-ignore
const setSpHash = payload => ({
  type: SET_SP_HASH,
  ...payload,
})

// @ts-ignore
const setLiveChatMode = payload => ({
  type: SET_LIVE_CHAT_MODE,
  ...payload,
})

const setTranslations = (payload: { translations: {
    [key: string]: string,
  } }) => ({
  type: SET_TRANSLATIONS,
  ...payload,
})

// @ts-ignore
const setItems = payload => ({
  type: SET_ITEMS,
  ...payload,
})
// @ts-ignore
const setSequenceStatistics = payload => ({
  type: SET_SEQUENCE_STATISTICS,
  ...payload,
})

// @ts-ignore
const setLiveStream = payload => ({
  type: SET_LIVESTREAM,
  ...payload,
})

// @ts-ignore
const setLiveChat = payload => ({
  type: SET_LIVE_CHAT,
  ...payload,
})
// @ts-ignore
const setSequenceLiveData = (payload: { liveData: ServerSettingsLiveData, sessionData: ServerSettingsSessionLiveData }) => ({
  type: SET_SEQUENCE_LIVE_DATA,
  ...payload,
})

// @ts-ignore
const setBrand = payload => ({
  type: SET_BRAND,
  ...payload,
})

// @ts-ignore
const setTotalInteractions = payload => ({
  type: SET_TOTAL_INTERACTIONS,
  ...payload,
})

// @ts-ignore
const setCurrentCard = payload => {
  return {
    type: SET_CARD_UUID,
    ...payload,
  }
}

// @ts-ignore
const setParentCard = payload => {
  return {
    type: SET_PARENT_CARD,
    ...payload,
  }
}

// @ts-ignore
const setVideoCurrentTime = payload => {
  return {
    type: SET_VIDEO_CURRENT_TIME,
    ...payload,
  }
}

// @ts-ignore
const setIsSetVideoCurrentTime = payload => {
  return {
    type: SET_ISSET_VIDEO_CURRENT_TIME,
    ...payload,
  }
}

// @ts-ignore
const setIsWidgetMaximized = payload => {
  return {
    type: IS_WIDGET_MAXIMIZED,
    ...payload,
  }
}

// @ts-ignore
const setWidgetEmbedded = payload => {
  return {
    type: IS_WIDGET_EMBEDDED,
    ...payload,
  }
}

// @ts-ignore
const setWidgetLocation = payload => {
  return {
    type: SET_WIDGET_LOCATION,
    ...payload,
  }
}
// @ts-ignore
const setWidgetLocationReferer = payload => {
  return {
    type: SET_WIDGET_LOCATION_REFERER,
    ...payload,
  }
}

// @ts-ignore
const setTitleStyle = payload => {
  return {
    type: TITLE_STYLE,
    ...payload,
  }
}

// @ts-ignore
const experienceEventShared = payload => {
  return {
    type: EXPERIENCE_EVENT_SHARED,
    ...payload,
  }
}

// @ts-ignore
const experienceEventLiked = payload => {
  return {
    type: EXPERIENCE_EVENT_LIKED,
    ...payload,
  }
}

// @ts-ignore
const setUserEmail = payload => {
  return {
    type: SET_USER_EMAIL,
    ...payload,
  }
}

// @ts-ignore
const setLiveStreamViewers = payload => {
  return {
    type: SET_LIVESTREAM_VIEWERS,
    ...payload
  }
}

const navigateCard = (cardId: string | null, history: History, initiatedBy: "User" | "System") => {
  return {
    type: NAVIGATE_CARD,
    cardId: cardId,
    history: history,
    initiatedBy: initiatedBy,
  }
}

const setExperienceRootCard = (root: CardData) => {
  return {
    type: SET_EXPERIENCE_ROOT_CARD,
    root: root,
  }
}

const setAutopilotCardFocus = (cardId: string) => {
  return {
    type: AUTOPILOT_CARD_FOCUS,
    cardId: cardId,
  }
}

// @ts-ignore
const setSurf = payload => {
  return {
    type: SET_SURF,
    ...payload
  }
}

const setBrandFollowing = (payload: boolean) => {
  return {
    type: SET_BRAND_FOLLOWING,
    ...{
      brandFollowing: payload,
    }
  }
}
const setLiveStreamSubscribed = (payload: boolean) => {
  return {
    type: SET_LIVESTREAM_SUBSCRIBED,
    ...{
      liveStreamSubscribed: payload,
    }
  }
}
const setGridAreaContent = (payload: typeof initialState.gridAreaContent) => {
  return {
    type: SET_GRID_AREA_CONTENT,
    ...{
      gridAreaContent: payload
    }
  }
}

export {
  setIsLoading,
  setSpId,
  setSpHash,
  setItems,
  setBrand,
  setTotalInteractions,
  setCurrentCard,
  setParentCard,
  setVideoCurrentTime,
  setIsSetVideoCurrentTime,
  setIsWidgetMaximized,
  setWidgetEmbedded,
  setTitleStyle,
  setSequenceStatistics,
  setSequenceLiveData,
  setWidgetLocation,
  setWidgetLocationReferer,
  setLiveStream,
  experienceEventShared,
  experienceEventLiked,
  setLiveStreamViewers,
  setUserEmail,
  setLiveChatMode,
  setTranslations,
  setSurf,
  setLiveChat,
  setGridAreaContent,
  setBrandFollowing,
  setAutopilotCardFocus,
  navigateCard,
  setExperienceRootCard,
  setLiveStreamSubscribed,
}
