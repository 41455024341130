const LS_VIDEO_TIMESTAMPS = 'contester_video_timestamps'
const VIDEO_ELEMENT = '.video-react-video'

function isLocalStorageAvailable() {
  try {
    let test = 'test-isLocalStorageAvailable'
    localStorage.setItem(test, test)
    localStorage.getItem(test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const localStorageAvailable = isLocalStorageAvailable()

/**
 * Multiple cards can share same video. So we track timestamp for particular video URL rather than particular card.
 * cardId param right now is completely ignored
 * @param cardId
 */
export const localStorageAppendToTimestamps = cardId => {
  if (localStorageAvailable) {
    const currentArrayOfTimes = JSON.parse(
      localStorage.getItem(LS_VIDEO_TIMESTAMPS) || '[]',
    )

    let videoUrl = document.querySelector(VIDEO_ELEMENT)?.firstChild?.src

    if (Array.isArray(currentArrayOfTimes) && videoUrl) {
      const index = currentArrayOfTimes.findIndex(
        el => el.videoUrl === videoUrl,
      )
      let newTimestamp = {
        videoUrl: videoUrl,
        time: document.querySelector(VIDEO_ELEMENT)?.currentTime || 0,
      }
      if (index >= 0) {
        currentArrayOfTimes[index] = newTimestamp
      } else {
        currentArrayOfTimes.push(newTimestamp)
      }
    }
    localStorage.setItem(
      LS_VIDEO_TIMESTAMPS,
      JSON.stringify(currentArrayOfTimes),
    )
  }
}

const getTimeForCard = cardId => {
  if (localStorageAvailable) {
    const _timestamps = localStorage.getItem(LS_VIDEO_TIMESTAMPS)
    if (!_timestamps) {
      return 0
    }
    const timestamps = JSON.parse(_timestamps)
    if (!Array.isArray(timestamps)) {
      return 0
    }

    let videoUrl = document.querySelector(VIDEO_ELEMENT)?.firstChild?.src
    if (!videoUrl) {
      return 0
    }

    return timestamps.find(el => el.videoUrl === videoUrl)?.time || 0
  } else {
    return 0
  }
}

export const loadVideoTime = cardId => {
  if (document.querySelector(VIDEO_ELEMENT)) {
    const time = getTimeForCard(cardId)
    document.querySelector(VIDEO_ELEMENT).currentTime = time
  }
}
