import React, { useState } from 'react'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { asset } from '../../utils/utils'
import './PinnedMessages.css';
import {pure} from "recompose";
import {isMobile} from "react-device-detect";

const PinnedMessages = ({
  liveChatLatestMessages,
  showChat,
  brand,
  isLiveChatVisible,
  pinned,
  isControlBarHidden,
  currentLiveStreamUrl
}) => {
  const [isPreviewSectionVisible, showPreview] = useState(!isMobile);
  const onPreviewIconClick = () => {
    showPreview(!isPreviewSectionVisible)
  }

  return (
    <div className={classnames("live-chat-preview", { expanded: !isControlBarHidden, 'is-width-reduced': !!currentLiveStreamUrl})}>
      {!isLiveChatVisible &&
        <>
          {
            <img
              className={classnames("show-chat-preview", { "is-opened": isPreviewSectionVisible })}
              onClick={() => onPreviewIconClick()}
              src={asset('Minimize.svg')}
            />
          }
          <div
            className={classnames("live-chat-container", { 'show': isPreviewSectionVisible })}
            onClick={() => {
              showChat();
            }}
          >
            {!isEmpty(liveChatLatestMessages) &&
              liveChatLatestMessages.map((message) => (
                <div key={`message-${message.id}`} className="preview-history-item">

                  <span className="preview-message"><span className="preview-username">{message.user.name}</span>{message.message}</span>
                </div>
              ))}
          </div>
        </>}
      { brand && pinned && pinned.length > 0 && (
        <div className="pinned-messages-wrapper" onClick={() => {
          showChat();
        }}>
          {pinned.map(p => {
            return <div key={`pinned-${p.id}`} className={classnames("pinned-message", { 'is-message-cropped': !currentLiveStreamUrl })}>

                <span className="preview-content">
                  <span className="preview-brandname">{p.user.name} &#11089;</span>{p.message}</span>
              </div>

          }
          )}</div>
      )}
    </div>
  )
}
export default pure(PinnedMessages)
