import {log} from "./log";

export const sendToWidget = (message: { type: string, value: any }) => {
  if (window.parent) {
    window.parent.postMessage(
      message,
      '*',
    )
    log(`sendToWidget(${JSON.stringify(message)})`)
  }
}