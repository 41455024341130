import {
    SET_ORGANIZATION_ID,
    SET_LIVE_CHATS_DATA,
    SET_LIVE_CHAT_HISTORY,
    SET_LIVE_CHAT_HISTORY_HASH,
    SET_SHARE_URL,
    ADD_MESSAGE_TO_LIVE_CHAT,
    ADD_MESSAGE_REPLY_TO_LIVE_CHAT,
    ADD_MESSAGE_REACTION_TO_LIVE_CHAT,
    SET_SESSION_ESTABLISHED,
    REPLACE_PENDING_MESSAGE_TO_LIVE_CHAT
} from '../../constants/actions'
import {LiveChatDataObject, LiveChatMessageReactionSent, LiveChatMessageReplySent, LiveChatMessageSent} from "../../events/ws";
import {LiveChatHistory} from "../../api/types";

const setOrganizationId = (payload: { organizationId: string }) => ({
    type: SET_ORGANIZATION_ID,
    ...payload,
})

const setLiveChatData = (payload: { liveChats: LiveChatDataObject[] }) =>
({
    type: SET_LIVE_CHATS_DATA,
    ...payload
})
const setLiveChatHistory = (payload: { history: LiveChatHistory }) => ({
        type: SET_LIVE_CHAT_HISTORY,
        ...payload
    })

const addMessageReactionToLiveChat = (payload: LiveChatMessageReactionSent) =>
({
    type: ADD_MESSAGE_REACTION_TO_LIVE_CHAT,
    ...payload
})

const addMessageReplyToLiveChat = (payload: LiveChatMessageReplySent) =>
({
    type: ADD_MESSAGE_REPLY_TO_LIVE_CHAT,
    ...payload
})

const addMessageToLiveChat = (payload: LiveChatMessageSent) =>
({
    type: ADD_MESSAGE_TO_LIVE_CHAT,
    ...payload
})

const replacePendingMessageToLiveChat = (payload: LiveChatMessageSent) =>
({
    type: REPLACE_PENDING_MESSAGE_TO_LIVE_CHAT,
    ...payload
})

const setLiveChatHistoryHash = (payload: { liveChatId: string, hash: string }) =>
({
    type: SET_LIVE_CHAT_HISTORY_HASH,
    ...payload
})
const setShareUrl = (payload: { shareUrl: string }) =>
    ({
        type: SET_SHARE_URL,
        ...payload
    })

const setSessionEstablished = (payload: boolean) =>
({
    type: SET_SESSION_ESTABLISHED,
    payload,
})

export { setOrganizationId, setLiveChatData, setLiveChatHistoryHash, setShareUrl, setLiveChatHistory, addMessageToLiveChat, replacePendingMessageToLiveChat, addMessageReplyToLiveChat, addMessageReactionToLiveChat, setSessionEstablished }
