import {LiveChatMessageReplySent, LiveChatMessageSent} from "../events/ws";
import {LiveChatHistoryItem} from "../api/types";

export const liveChatHistoryMessageFormatter = (message: LiveChatMessageSent): LiveChatHistoryItem => {

    return {
        id: message.id,
        message: message.message,
        createdAt: message.createdAt,
        pinned: false,
        replyTo: undefined,
        replies: [],
        reactions: [],
        user: message.user,
        type: 'Message',
        latestThreadMessageCreatedAt: '',
    }
}

export const liveChatHistoryMessageReplyFormatter = (message: LiveChatMessageReplySent): LiveChatHistoryItem => {

    return {
        id: message.id,
        message: message.message,
        createdAt: message.createdAt,
        pinned: false,
        replyTo: message.replyMessageId,
        replies: [],
        reactions: [],
        user: message.user,
        type: 'Message',
        latestThreadMessageCreatedAt: '',
    }
}