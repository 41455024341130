import {pure} from "recompose";

const DateTimeDisplay = ({ value, type }) => {
  return (
    <div className="countdown">
      <span className="countdown-value">{value}</span>
      <span>{type}</span>
    </div>
  )
}

export default pure(DateTimeDisplay)
