import { useEffect, useState } from 'react'
import moment from 'moment'
/**
 * Hook that calculates difference between Livestream scheduled date and current time.
 * @param {Number} countDownDate - date (in unix format) when livestream starts. We use moment.unix for convert it.
 * @returns {Array} - array in format [days, hours, minutes]. The time that left until livestream starts
 */
const useCountdown = (countDownDate) => {
  const [isCountdownFinished, setFinishedCountdown] = useState(false);

  const setTimeDate = () => {
    const then = moment.unix(countDownDate)
    const now = moment()
    const daysLeft = then.diff(now, 'days')
    const dateInHours = then.diff(now, 'hours')
    const hoursLeft = dateInHours - daysLeft * 24
    const dateInMinutes = then.diff(now, 'minutes')
    const minutesLeft = dateInMinutes % 60
    // Check if scheduled date is in past -- show zeros at countdown
    if (minutesLeft <= 0) {
      setFinishedCountdown(true);
      setDays(0)
      setHours(0)
      setMinutes(0)
    } else {
      setDays(daysLeft)
      setHours(hoursLeft)
      setMinutes(minutesLeft)
    }
  }

  const [days, setDays] = useState(undefined)
  const [hours, setHours] = useState(undefined)
  const [minutes, setMinutes] = useState(undefined)

  useEffect(() => {
    if (!isCountdownFinished) {
      setTimeDate()
    }
    const interval = setInterval(() => {
      setTimeDate()
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate, isCountdownFinished])

  return [days, hours, minutes, isCountdownFinished]
}

export { useCountdown }
