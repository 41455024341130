import React from 'react'
import moment from 'moment';
import classnames from 'classnames'
import {pure} from "recompose";

export default pure(function SingleComment({
    createdAt,
    message,
    reply,
    reactions,
    picture,
    userId,
    name,
}) {
    const messageTime = moment.unix(createdAt)
    const messageTimeFormatted = moment.unix(createdAt).format('H:mm')
    let now = moment();
    const timeDiff = now.diff(messageTime, 'days');
    let days = moment.unix(createdAt).fromNow('d');
    return (
        <>
            <div className="comment-wrapper">

                <div className={classnames("chat-message", { 'is-reply': reply })} >
                    <div className="comment-avatar-container">
                        <img className="comment-avatar" src={picture} alt="avatar" />
                    </div>
                    <div className="comment-body">
                        <div className="comment-top-section">
                            <span className="comment-name">{name}</span>
                            <span className="comment-created">{timeDiff < 1 ? messageTimeFormatted : days}</span>
                        </div>
                        <span className="comment-message">{message}</span>
                    </div>

                </div>
                <div className="comment-reaction"></div>
            </div>
        </>
    )
})
