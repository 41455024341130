export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_SP_ID = 'SET_SP_ID'
export const SET_SP_HASH = 'SET_SP_HASH'
export const SET_LIVE_CHAT_MODE = 'SET_LIVE_CHAT_MODE'
export const SET_ITEMS = 'SET_ITEMS'
export const SET_BRAND = 'SET_BRAND'
export const SET_TOTAL_INTERACTIONS = 'SET_TOTAL_INTERACTIONS'
export const SET_CARD_UUID = 'SET_CARD_UUID'
export const SET_HEADER = 'SET_HEADER'
export const SET_PARENT_CARD = 'SET_PARENT_CARD'
export const SET_VIDEO_CURRENT_TIME = 'SET_VIDEO_CURRENT_TIME'
export const SET_ISSET_VIDEO_CURRENT_TIME = 'SET_ISSET_VIDEO_CURRENT_TIME'
export const IS_WIDGET_MAXIMIZED = 'IS_WIDGET_MAXIMIZED'
export const IS_WIDGET_EMBEDDED = 'IS_WIDGET_EMBEDDED'
export const TITLE_STYLE = 'TITLE_STYLE'
export const SET_SEQUENCE_STATISTICS = 'SET_SEQUENCE_STATISTICS'
export const SET_SEQUENCE_LIVE_DATA = 'SET_SEQUENCE_LIVE_DATA'
export const SET_WIDGET_LOCATION = 'SET_WIDGET_LOCATION'
export const SET_WIDGET_LOCATION_REFERER = 'SET_WIDGET_LOCATION_REFERER'
export const SET_LIVESTREAM = 'SET_LIVESTREAM'
export const SET_LIVE_CHAT = 'SET_LIVE_CHAT'
export const SET_ORGANIZATION_ID = 'SET_ORGANIZATION_ID'
export const SET_LIVE_CHATS_DATA = 'SET_LIVE_CHATS_DATA'
export const SET_LIVE_CHAT_HISTORY = 'SET_LIVE_CHAT_HISTORY'
export const SET_LIVE_CHAT_HISTORY_HASH = 'SET_LIVE_CHAT_HISTORY_HASH'
export const SET_SHARE_URL = 'SET_SHARE_URL'
export const ADD_MESSAGE_TO_LIVE_CHAT = 'ADD_MESSAGE_TO_LIVE_CHAT'
export const REPLACE_PENDING_MESSAGE_TO_LIVE_CHAT = 'REPLACE_PENDING_MESSAGE_TO_LIVE_CHAT'
export const ADD_MESSAGE_REPLY_TO_LIVE_CHAT = 'ADD_MESSAGE_REPLY_TO_LIVE_CHAT'
export const ADD_MESSAGE_REACTION_TO_LIVE_CHAT = 'ADD_MESSAGE_REACTION_TO_LIVE_CHAT'
export const SET_SESSION_ESTABLISHED = 'SET_SESSION_ESTABLISHED'
export const EXPERIENCE_EVENT_LIKED = 'EXPERIENCE_EVENT_LIKED'
export const EXPERIENCE_EVENT_SHARED = 'EXPERIENCE_EVENT_SHARED'
export const SET_LIVESTREAM_VIEWERS = 'SET_LIVESTREAM_VIEWERS'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS'
export const SET_SURF = 'SET_SURF'
export const SET_GRID_AREA_CONTENT = 'SET_GRID_AREA_CONTENT'
export const SET_BRAND_FOLLOWING = 'SET_BRAND_FOLLOWING'
export const SET_LIVESTREAM_SUBSCRIBED = 'SET_LIVESTREAM_SUBSCRIBED'
export const AUTOPILOT_CARD_FOCUS = 'AUTOPILOT_CARD_FOCUS'
export const NAVIGATE_CARD = 'NAVIGATE_CARD'
export const SET_EXPERIENCE_ROOT_CARD = 'SET_EXPERIENCE_ROOT_CARD'

export interface OrganizationTranslations {
  organizationId: string,
  translations: {
    [key: string]: string,
  },
}