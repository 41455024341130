import React, {useEffect, useState, useRef, useCallback} from 'react'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { useStateValue } from '../state-provider/StateProvider'
import {asset, filterAlphanumeric} from '../../utils/utils'
import {LiveChatDataObject, ws} from '../../events/ws'
import { JoinChatStep } from './ChatContainer'
import {addMessageToLiveChat} from "./Chat.action";
import {pure} from "recompose";
import {sendToWidget} from "../../utils/postMessages";
import {setUserEmail} from "../app/App.action";
import {log} from "../../utils/log";
interface Props {
  organizationId: string
  experienceId: string
  referenceId: string
  joinChatStep: string
  setJoinChatStep: (val: JoinChatStep) => void
  liveChat: LiveChatDataObject | undefined
  isInputClean: boolean
  cleanInputs: (val: boolean) => void
  isLiveChatVisible: boolean,
  focusUserNameInput: (val: boolean) => void,
  isUserNameInputFocused: boolean
}

const MessageSendContainer: React.FC<Props> = ({
  organizationId,
  experienceId,
  referenceId,
  joinChatStep,
  setJoinChatStep,
  liveChat,
  isInputClean,
  cleanInputs,
  isLiveChatVisible,
  focusUserNameInput,
  isUserNameInputFocused
}) => {

  const [
    { brand, userEmail, translations },
    dispatch,
  ] = useStateValue()

  const [userName, setUserName] = useState('')
  const [userEmailField, setUserEmailField] = useState('')
  const [chatMessage, onMessageHandle] = useState('')
  const [isUserNameValid, setIsUserNameValid] = useState(false)


  useEffect(() => {
    if (userEmail) {
      setUserEmailField(userEmail)
    }
  }, [userEmail]);

  useEffect(() => {
    if (isInputClean) {
      setUserName('')
      setUserEmailField('')
      cleanInputs(false)
    }
  }, [isInputClean])

  useEffect(() => {
    setIsUserNameValid(filterAlphanumeric(userName?.trim())?.length > 0)
  }, [userName])

  useEffect(() => {
    if (liveChat && liveChat.profile) {
      setUserName(liveChat.profile.name)
      setJoinChatStep('joined')
    }
  }, [liveChat])

  const sendJoinRequest = useCallback(async () => {
    try {
      const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone
      ws.sendJoinLiveChat(
        organizationId,
        referenceId,
        userName,
        userEmailField,
        timezone,
      )
      // Handle join conversation
      focusUserNameInput(false)
      setJoinChatStep('joined')
      dispatch(setUserEmail({ userEmail: userEmailField }))
      sendToWidget({ type: 'update-user-email', value: userEmailField })

    } catch (e) {
      log(`Error sending chat join request`, e)
    }
  }, [ws, console, organizationId, referenceId, userName, userEmailField])

  const sendMessage = useCallback(async () => {
    if (chatMessage && chatMessage.length > 0) {
      try {
        dispatch(addMessageToLiveChat({
          createdAt: (new Date().getTime() / 1000).toString(),
          experienceIds: [experienceId],
          id: 'PENDING',
          liveChatId: liveChat!!.liveChatId,
          referenceId: referenceId,
          sessionId: undefined,
          user: liveChat!!.profile,
          message: chatMessage,
        }))
        onMessageHandle('')
        ws.sendLiveChatMessage(
          organizationId,
          referenceId,
          chatMessage,
        )
      } catch (e) {
        log(`Error sending chat message`, e)
      }
    }
  }, [chatMessage, dispatch, experienceId, liveChat, referenceId, ws, organizationId, console])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (e.currentTarget.name === 'name' && isUserNameValid) {
        setJoinChatStep('email')
      } else if (e.currentTarget.name === 'email') {
        sendJoinRequest()
      }
    }
  }

  const onInputBlur = useCallback((e: React.FocusEvent<HTMLInputElement>): void => {
    if (e?.relatedTarget?.id !== 'join-btn') {
      focusUserNameInput(false)
    }
  }, [])

  const inputRef = useRef<HTMLInputElement | null>(null)
  const emailRef = useRef<HTMLInputElement | null>(null)

  const trimBrandName = brand?.title?.trim() ?? ""

  const onKeyDownSendMessage = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
        (e.code === 'Enter' || e.code === 'NumpadEnter') &&
        chatMessage?.trim()?.length > 0
    ) {
      sendMessage()
    }
  }, [chatMessage])

  return isLiveChatVisible ? (
    <>
      {['name', 'email'].includes(joinChatStep) ? (
        <>
          <div
            className={classnames('send-message-container', {
              expanded: isUserNameInputFocused
            })}
          >
            {joinChatStep === 'name' &&
              <div>
                <label className={classnames("name-label", { show: isUserNameInputFocused })}>
                  <span>
                    {translations['LiveChatInputNameTitle']}
                  </span>
                  <img
                  onClick={() => { focusUserNameInput(false) }}
                    src={asset('cross.svg')}
                    className="close-input-selection"
                  />
                </label>
              </div>
            }
            {joinChatStep === 'email' && (
              <div>
                <label className={classnames("email-label", { show: isUserNameInputFocused })}>
                  <span>
                    {translations['LiveChatInputEmailTitle'].replace('{0}', trimBrandName)}
                  </span>
                  <img
                    onClick={() => { focusUserNameInput(false) }}
                    src={asset('cross.svg')}
                    className="close-input-selection"
                  />
                </label>
              </div>
            )}
            <div className="message-wrapper">
              <img className="comment-avatar" src={asset('avatar-empty.png')} />
              {joinChatStep === 'name' ? (
                <>
                  <input
                    onBlur={onInputBlur}
                    tabIndex={-1}
                    ref={inputRef}
                    onFocus={() => focusUserNameInput(true)}
                    value={userName}
                    className="send-message"
                    name={'name'}
                    autoComplete="name"
                    onChange={e => {
                      if (e.target.value?.length <= 15)
                        setUserName(e.target.value)
                    }}
                    placeholder={translations['LiveChatInputNameLabel']}
                    type="text"
                    onKeyDown={handleKeyPress}
                  />
                  <button
                    id="join-btn"
                    className="join-button"
                    onClick={() => {
                      if (isUserNameValid) {
                        setJoinChatStep('email')
                      }
                    }}
                    type="button"
                  >
                    {translations['LiveChatButtonNextLabel']}
                  </button>
                </>
              ) : (
                <>
                  <input
                    tabIndex={-1}
                    value={userEmailField}
                    ref={emailRef}
                    className="send-message"
                    name={'email'}
                    onBlur={onInputBlur}
                    autoComplete="email"
                    onFocus={()=>focusUserNameInput(true)}
                    onChange={e => {
                      if (e.target.value?.length <= 128)
                        setUserEmailField(e.target.value)
                    }}
                    placeholder={translations['LiveChatInputEmailLabel']}
                    type="text"
                    onKeyDown={handleKeyPress}
                  />
                  <button
                    id="join-btn"
                    className="join-button"
                    disabled={!isUserNameValid}
                    onClick={sendJoinRequest}
                    type="button"
                  >
                    {isEmpty(userEmailField) ? translations['LiveChatButtonSkipLabel'] : translations['LiveChatButtonJoinLabel']}
                  </button>
                </>
              )}
              </div>
            </div>
        </>
      ) : (
        <div className="send-message-container">
          <img className="comment-avatar" src={liveChat?.profile?.picture} />
            <input
              tabIndex={-1}
              className="send-message"
              value={chatMessage}
              name="message"
              onInput={e => {
                let val = (e.target as HTMLTextAreaElement).value
                if (val?.length < 255) onMessageHandle(val)
              }}
              onKeyDown={onKeyDownSendMessage}
              placeholder={
                brand?.title ? translations['LiveChatInputMessageForBrandLabel'].replace('{0}', trimBrandName) : translations['LiveChatInputMessageLabel']
              }
              type="text"
          />
          {chatMessage?.trim()?.length > 0 ? (
            <img
              onClick={sendMessage}
              className="send-icon"
              src={asset('./Icon-feather-send.svg')}
            />
          ) : (
            <img
              className="send-icon-disabled"
              src={asset('./Icon-feather-send-disabled.svg')}
            />
          )}
        </div>
      )}
    </>
  ) : (
    <></>
  )
}

export default pure(MessageSendContainer)
