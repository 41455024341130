import React, {useEffect, useState} from 'react'
import moment from 'moment'
import classnames from 'classnames'
import DateTimeDisplay from './DateTimeDisplay'
import { useCountdown } from '../../hooks/useCountdown'
import { asset } from '../../utils/utils'
import './Countdown.css'
import classNames from 'classnames'
import {pure} from "recompose";
import {useStateValue} from "../state-provider/StateProvider";

const Countdown = ({
  liveStream,
  showNotifiedMessage,
  isWidgetMaximized,
  isSubscribed,
  isReminderFormVisible,
  liveStreamConnected,
}) => {
  const [{ translations }] = useStateValue()

  const [days, hours, minutes, isCountdownFinished] = useCountdown(liveStream?.scheduledAt)
  const [startsAnytime, setStartsAnytime] = useState(false)

  useEffect(() => {
    if (liveStream) {
      let diffSeconds = (new Date().getTime() / 1000) - parseInt(liveStream.scheduledAt)
      setStartsAnytime(diffSeconds > -60 && diffSeconds < 600 || liveStream.status == "Connected")
    } else {
      setStartsAnytime(false)
    }
  }, [liveStream, isCountdownFinished])

  useEffect(() => {
    if (isCountdownFinished) {
      showNotifiedMessage(false)
    }
  }, [isCountdownFinished])

  return (
    <div className={classNames('countdown-container', isWidgetMaximized ? 'maximized' : 'minimized', {hidden: !isReminderFormVisible})}>
      <p className="title">{liveStream?.title}</p>
      {!isCountdownFinished && <p className="scheduled-at">
        {moment.unix(liveStream?.scheduledAt).format(translations['SubscriptionEventDateTimeFormat'])}
      </p>}
      <div className="show-counter">
        { liveStreamConnected ? (
            <>
              <span className="previously-live" style={{color: "rgba(0, 0, 0, 0.0)"}}>{'.'}</span>
            </>
        ) : (
            <>
              {
                isCountdownFinished ? (
                        <>
                          { startsAnytime ? (
                              <span className="previously-live">{translations['SubscriptionFormCountdownAnytimeLabel']}</span>
                          ) : (
                              <span className="previously-live">{translations['SubscriptionFormCountdownFinishedLabel']}</span>
                          )}
                        </>
                    ) :
                    <>
                      <DateTimeDisplay value={days} type={translations['SubscriptionEventCountdownDaysSuffix']} />
                      <DateTimeDisplay value={hours} type={translations['SubscriptionEventCountdownHoursSuffix']} />
                      <DateTimeDisplay value={minutes} type={translations['SubscriptionEventCountdownMinutesSuffix']} />
                    </>
              }
            </>
        )}
      </div>

        <>
          { !isCountdownFinished ? <div className={classnames("thank-you-label", { show: isSubscribed })}>
            <span>{translations['SubscriptionFormConfirmationLabel']}</span>
            {<img src={asset('icon-star.svg')} />}
          </div> : null}
        </>
    </div>
  )
}

export default pure(Countdown)
